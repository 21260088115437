<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Parola Yenile</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Eski Parola</label>
              <input
                name="oldpassword"
                type="password"
                class="form-control"
                placeholder="Eski Parola"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">Yeni Parola</label>
              <input
                name="password"
                type="password"
                class="form-control"
                placeholder="Yeni Parola"
              />
            </div>
          </div>
          <div class="col-sm-6 col-md-6">
            <div class="form-group">
              <label class="form-label">Yeni Parola (Tekrar)</label>
              <input
                name="repassword"
                type="password"
                class="form-control"
                placeholder="Yeni Parola (Tekrar)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary"
        >
          Parola Güncelle
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    document.title = "Parola Güncelle";
  },
  data() {
    return {
      pageGet: [],
    };
  },
  methods: {
    save() {
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Bilgiler kayıt ediliyor..";
      let oldpassword = document.querySelector("input[name=oldpassword]").value;
      let password = document.querySelector("input[name=password]").value;
      let repassword = document.querySelector("input[name=repassword]").value;
      if (password != repassword) {
        /**Parolalar uyuşmuyor */
        this.$vToastify.warning("Parolalar uyuşmuyor", "Uyarı!");
      } else if (oldpassword == null || oldpassword == "") {
        /**Eski parola girilmeli */
        this.$vToastify.warning("Eski parola girilmedi", "Uyarı!");
      } else if (password == null || password == "") {
        /**Eski parola girilmeli */
        this.$vToastify.warning("Yeni parola girilmedi", "Uyarı!");
      } else if (repassword == null || repassword == "") {
        /**Eski parola girilmeli */
        this.$vToastify.warning("Yeni parola tekrar girilmedi", "Uyarı!");
      } else {
        let passwordSave = {
          oldpassword: oldpassword,
          password: password,
        };
        this.$store
          .dispatch("passwordProfileUpdate", passwordSave)
          .then((value) => {
            this.$vToastify.success("Parola güncelleme başarılı", "Başarılı!");
            document.getElementById("saveButton").disabled = false;
            document.getElementById("saveButton").innerHTML =
              "Parola Güncelle";
          });
      }
    },
  },
  components: {},
  mounted() {},
};
</script>